<template>
  <v-container>
    <div v-if="loading" class="d-flex justify-center align-center" style="min-height: calc(100vh - 200px);">
      <v-progress-circular
        :size="100"
        :width="7"
        color="purple"
        indeterminate
      />
      <v-overlay :value="true" :opacity=".7" absolute></v-overlay>
    </div>
    <div v-else class="d-flex justify-center">
      <v-sheet>
        <v-card-text>
          <p style="line-height: 28px;">
            Something went wrong. Please contact admin via email <a href="mailto:info@revampcybersecurity.com">info@revampcybersecurity.com</a>
          </p>
        </v-card-text>
      </v-sheet>
    </div>
  </v-container>
</template>
<script>
  import { Post } from '@/api'
  export default {
    name: 'ConfirmEmail',

    data () {
      return {
        loading: true
      }
    },

    async mounted() {
      console.log(this.$route)
      const verify_email = this.$route.params.verify_email
      const res = await Post('users/confirm_email', {verify_email})
      if (res.status == 'success') {
        this.$router.push({name: 'ThankYou'})
      } else {
        this.loading = false
      }
    }
  }
</script>